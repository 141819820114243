import Reveal from 'stimulus-reveal-controller';

export default class extends Reveal {
  toggle() {
    super.toggle();
    const isPasswordButton = this.itemTargets.some((item) => item.id === 'password_change');

    if (isPasswordButton) {
      const passwordButton = document.getElementById('password_change_button');
      const passwordIcon = document.getElementById('password_change_icon');

      passwordButton.classList.toggle('is-danger');
      passwordButton.classList.toggle('is-light');
      passwordIcon.classList.toggle('fa-lock');
      passwordIcon.classList.toggle('fa-unlock-alt');
    }
  }
}
